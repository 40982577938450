<script setup lang="ts">
const props = defineProps<{ modelValue: string, revenueSeries: { name: string, data: number[] }[], salesByCategorySeries: number[] }>()
const emits = defineEmits(['update:modelValue'])

const chartDate = ref(props.modelValue)

watch(chartDate, () => {
  emits('update:modelValue', chartDate.value)
})
</script>

<template>
  <header class="z-0 mb-5 relative py-3 flex items-start lg:items-center gap-4 justify-between bg-white dark:bg-dark-light p-2 rounded shadow">
    <nav>
      <FormsRange v-model="chartDate" label="Selecciona el rango de fechas para filtrar el gráfico" />
      <p class="pl-0.5 py-2 text-sm font-bold">
        Estas viendo las fechas

        <span v-if="chartDate.split('a').at(0)">
          {{ chartDate.split('a').at(0) }}
        </span>
        hasta
        <span v-if="!chartDate.split('a').at(1)" class="text-primary">
          esperando la fecha de finalización...
        </span>
        <span v-if="chartDate.split('a').at(1)">
          {{ chartDate.split('a').at(1) }}
        </span>
      </p>
    </nav>
    <UtilitiesDownloadCharts
      :sales-by-category-series="salesByCategorySeries"
      :revenue-series="revenueSeries"
      chart-id="chart"
    />
  </header>
</template>
