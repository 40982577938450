<script setup lang="ts">
const props = defineProps<{
  revenueSeries: { name: string, data: number[] }[]
  salesByCategorySeries: number[]
  chartId: string
}>()

const globalStore = useGlobal()

async function handleDownloadCharts() {
  if (import.meta.client) {
    const html2pdf = (await import('html2pdf.js')).default
    const element = globalThis.document.getElementById(props.chartId)!

    html2pdf(element, {
      margin: [0.2, 0.2, 0.2, 0.2],
      filename: `estadisticas-generadas-by-efici.pdf`,
      image: { type: 'jpeg', quality: 0.95 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'in', format: [16, 9], orientation: 'landscape' },
    })
  }
}

async function handleDownloadExcelCommision() {
  if (import.meta.client) {
    const XLSX = await import('xlsx')
    const BN = XLSX.utils.book_new()

    const revenueSheetData = [
      ['Mes', '2024', '2023'],
      ['Enero', props.revenueSeries[0].data[0], props.revenueSeries[1].data[0]],
      ['Febrero', props.revenueSeries[0].data[1], props.revenueSeries[1].data[1]],
      ['Marzo', props.revenueSeries[0].data[2], props.revenueSeries[1].data[2]],
      ['Abril', props.revenueSeries[0].data[3], props.revenueSeries[1].data[3]],
      ['Mayo', props.revenueSeries[0].data[4], props.revenueSeries[1].data[4]],
      ['Junio', props.revenueSeries[0].data[5], props.revenueSeries[1].data[5]],
      ['Julio', props.revenueSeries[0].data[6], props.revenueSeries[1].data[6]],
      ['Agosto', props.revenueSeries[0].data[7], props.revenueSeries[1].data[7]],
      ['Septiembre', props.revenueSeries[0].data[8], props.revenueSeries[1].data[8]],
      ['Octubre', props.revenueSeries[0].data[9], props.revenueSeries[1].data[9]],
      ['Noviembre', props.revenueSeries[0].data[10], props.revenueSeries[1].data[10]],
      ['Diciembre', props.revenueSeries[0].data[11], props.revenueSeries[1].data[11]],
    ]

    const salesSheetData = [
      ['Categoría', 'Ventas'],
      ['Producto A', props.salesByCategorySeries[0]],
      ['Producto B', props.salesByCategorySeries[1]],
      ['Producto C', props.salesByCategorySeries[2]],
    ]

    const revenueSheet = XLSX.utils.aoa_to_sheet(revenueSheetData)
    const salesSheet = XLSX.utils.aoa_to_sheet(salesSheetData)

    XLSX.utils.book_append_sheet(BN, salesSheet, 'Ventas por Categoría')
    XLSX.utils.book_append_sheet(BN, revenueSheet, 'Ingresos')

    XLSX.writeFileXLSX(BN, `tabla-de-estadisticas-de-${globalStore.place?.name}.xlsx`, {
      type: 'array',
      bookType: 'xlsx',
    })
  }
}
</script>

<template>
  <nav class="flex items-center gap-3">
    <button
      class="btn btn-danger"
      @click="handleDownloadCharts"
    >
      {{ $t("Download") }} PDF
    </button>
    <button
      class="btn btn-success"
      @click="handleDownloadExcelCommision"
    >
      {{ $t("Download") }} Excel
    </button>
  </nav>
</template>
